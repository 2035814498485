<template>
  <div id="create-activity" :style="{ position: 'relative' }">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5 d-flex justify-space-between">
          <h2 class="d-flex">
            <v-icon @click="back()">arrow_back_ios</v-icon>
            <div class="d-flex align-center">
              {{ announcementID ? 'Edit Announcement' : 'Create Announcement' }}
            </div>
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
      class="my-10"
    ></v-progress-linear>
    <v-form ref="formReference" v-model="form">
      <v-row no-gutters class="pb-10" v-if="!isLoading">
        <v-col md="6">
          <div class="pa-6 pt-0 pl-10">
            <div>
              <div>
                <div class="d-flex justify-space-between align-center">
                  <h2>Announcement</h2>
                  <p class="link" @click="templateDiaglog = true">Using template</p>
                </div>
                <div class="alert">
                  <v-alert
                    :value="checkSuccess"
                    transition="scale-transition"
                    type="success"
                    style="font-size: 14px; padding: 12px"
                  >
                    {{
                      announcementID
                        ? 'Edit announcement successfully'
                        : 'Create announcement successfully'
                    }}
                  </v-alert>
                  <v-alert
                    :value="checkError"
                    transition="scale-transition"
                    type="error"
                    style="font-size: 14px; padding: 12px"
                  >
                    An error occurred
                  </v-alert>
                </div>

                <v-row class="align-center mt-3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="templateTitle"
                      outlined
                      :loading="isLoading"
                      :disabled="isLoading"
                      :rules="[v => !!v || 'This field is required']"
                      label="Title of template *"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5" class="pt-2 pb-2">
                    <v-autocomplete
                      v-model="templateTags"
                      :items="catListUpdate"
                      item-text="anouncementTagName"
                      item-value="anouncementTagID"
                      :rules="[v => v.length > 0 || 'This field is required']"
                      outlined
                      multiple
                      label="Tags *"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" class="pt-2 pb-2">
                    <template>
                      <div class="text-center">
                        <div @click="openEditReward" class="d-flex align-center">
                          <img
                            :style="{
                              color: '#246AFF',
                            }"
                            src="../../assets/images/icon-edit-underline-link.svg"
                            alt=""
                          />
                          <h4 class="link ml-5">Edit announcement tags</h4>
                        </div>
                      </div>
                    </template>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="templateContext"
                      outlined
                      counter="1000"
                      :loading="isLoading"
                      :disabled="isLoading"
                      :rules="[
                        v => !!v || 'This field is required',
                        v => v.length <= 1000 || 'Max 1000 characters',
                      ]"
                      label="Context of template *"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <div class="editor">
                  <span :style="{ color: errorEdittor ? 'red' : '' }">Description *</span>
                  <vue-editor
                    :class="'mt-4'"
                    :style="{ border: errorEdittor ? '2px solid red' : '' }"
                    id="editor"
                    v-model="editor"
                  >
                  </vue-editor>
                  <p :style="{ color: 'red' }" v-if="errorEdittor">This field is require</p>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col md="6" class="px-10">
          <v-row class="pb-5">
            <h2>Select sending time</h2>
          </v-row>
          <v-row class="pb-5">
            <img src="../../assets/images/announ_calendar.svg" alt="" srcset="" />
            <date-picker
              :format="'DD/MM/YYYY'"
              placeholder="Chọn ngày"
              v-model="startDate"
              valueType="format"
              :class="'pick-time date'"
            ></date-picker>
            <date-picker
              type="time"
              :class="'pick-time time'"
              :format="'HH:mm:ss'"
              v-model="startTime"
              placeholder="Chọn giờ"
            ></date-picker>
          </v-row>
          <v-row class="d-flex align-center">
            <img src="../../assets/images/announ_recursive.svg" alt="" srcset="" />
            <v-autocomplete
              v-model="repeatID"
              :items="repeatList"
              item-text="repeatText"
              item-value="repeatText"
              outlined
              dense
              class="mt-5"
              :style="{ marginLeft: '5px', maxWidth: '307px' }"
            ></v-autocomplete>
            <h4 v-if="repeatID !== 'ONCE'" class="pl-3">Until Date</h4>
            <date-picker
              v-if="repeatID !== 'ONCE'"
              :format="'DD/MM/YYYY'"
              placeholder="Chọn ngày"
              v-model="untilDate"
              valueType="format"
              :class="'pick-time date'"
            ></date-picker>
          </v-row>
          <v-row class="d-flex flex-column">
            <h2 class="pt-5">Select Recipients</h2>
            <div class="d-flex align-center mt-5">
              <div class="d-flex mr-3" v-for="(header, index) in headers" :key="header.value">
                <Autocomplete
                  v-if="showAutocomplete(index)"
                  @setFilter="setFilter"
                  :styleBorder="'out-lined'"
                  placeholder="Select..."
                  :keyFilter="header.value"
                  :selectedInit="filters[header.value]"
                  :listItem="header.items"
                  :announLabel="header.announLabel"
                />
              </div>
              <img
                class="ml-auto mb-6 search-btn"
                @click="searchStudent()"
                src="../../assets/images/search-icon.svg"
                alt=""
                srcset=""
              />
            </div>
          </v-row>
          <v-row>
            <div
              v-if="isLoading1"
              class="d-flex flex-column justify-center"
              text-center
              :style="{ width: '100%' }"
            >
              <v-progress-linear
                color="indigo accent-2"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <h3 class="text-center mt-2">Loading...</h3>
            </div>
            <div v-else>
              <AnnounTable
                :selectedAnnouncementTarget="userIDs"
                :students="userList"
                @selectedUser="handleUserSelected"
              />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-footer
      :style="{ position: 'sticky !important' }"
      height="60"
      style="width: 100%"
      color="#fff"
      class="pl-10 pr-10"
    >
      <v-row justify="end" no-gutters>
        <v-btn
          class="mr-3 px-10"
          color="primary"
          @click="createNew()"
          :disabled="form && editor && typeof checkUpdatedTarget() !== 'string' ? false : true"
          >Save</v-btn
        >
        <v-btn class="mr-3 px-10" @click="back()">Cancel</v-btn>
      </v-row>
    </v-footer>
    <!-- <v-footer
      :style="{ position: 'sticky !important' }"
      height="60"
      style="width: 100%"
      color="#fff"
      class="pl-10 pr-10"
      v-if="!isLoading"
    >
      <div class="d-flex justify-end" style="width: 100%">
        <div class="d-flex flex-row-reverse align-center" style="width: 100%">
          <v-btn
            class="mr-3"
            color="primary"
            @click="createNew()"
            :disabled="form && editor && typeof checkUpdatedTarget() !== 'string' ? false : true"
            >Save</v-btn
          >
          <v-btn class="mr-3" @click="back()">Cancel</v-btn>
        </div>
      </div>
    </v-footer> -->
    <v-dialog v-if="dialogH" v-model="dialogH" width="500" persistent>
      <v-card>
        <v-card-title
          :class="'mb-3'"
          :style="{
            width: '100%',
          }"
        >
          <div
            :style="{
              width: '100%',
            }"
          >
            <div class="d-flex justify-space-between mb-2">
              <h3>Announcement template tags</h3>
              <img
                @click="closeDialogCategory()"
                src="../../assets/images/icon-close.svg"
                alt=""
                srcset=""
              />
            </div>
            <v-divider></v-divider>
          </div>
          <v-divider></v-divider>
        </v-card-title>
        <v-card-text>
          <div class="d-flex" :style="{ width: '100%', flexWrap: 'wrap' }">
            <v-form
              class="d-flex"
              :style="{ width: '100%' }"
              ref="formCategory"
              v-model="formCategory"
            >
              <v-text-field
                dense
                v-model="catName"
                label="Tag name"
                outlined
                @input="errCatName = ''"
                @blur="$refs.formCategory.resetValidation()"
                :rules="[validateRequire]"
                :class="'hidden-detail-err '"
              ></v-text-field>
              <v-btn
                :disabled="!formCategory"
                @click="saveCategory()"
                class="ml-2"
                dense
                outlined
                color="primary"
              >
                <img src="../../assets/images/icon-plus-round2.svg" alt="" srcset="" class="mr-2" />
                <p>Add new</p>
              </v-btn>
            </v-form>
          </div>
          <div
            :style="{
              maxHeight: '300px',
              overflowY: 'scroll',
              overflowx: 'hidden',
              paddingTop: '3px',
            }"
          >
            <v-form ref="formTemplateTags" v-model="formTemplateTags">
              <template>
                <div
                  class="d-flex align-center"
                  v-for="(item, index) in catListUpdate"
                  :key="index"
                >
                  <v-col cols="10">
                    <v-text-field
                      :key="index"
                      dense
                      v-model="item.anouncementTagName"
                      outlined
                      label="Name"
                      :rules="[validateRequire]"
                      :class="'hidden-detail-input'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon color="red light-2" large @click="removeTag(item.anouncementTagID)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </v-col>
                </div>
              </template>
            </v-form>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mr-2">
          <v-spacer></v-spacer>
          <v-btn outlined class="mb-2" @click="closeDialogCategory()"> Cancel </v-btn>
          <v-btn
            :disabled="!formTemplateTags"
            color="primary"
            class="mb-2"
            @click="saveEditCategoryList()"
          >
            Save for edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <popup-confirm
      @confirmNormal="confirmSendNormal"
      @confirmUpdate="confirmSendUpdatable"
      :maxWidth="900"
      confirmSendAll="Send All (Updatable)!"
      confirmSend="Send All Selected!"
      ref="confirmCreateAnnoun"
    >
      <v-card-title :class="'pt-0'" slot="icon">
        <div :style="{ margin: '0 auto' }">
          <img src="../../assets/images/announ-update-user.svg" alt="" srcset="" /></div
      ></v-card-title>
      <v-card-title :class="'pt-0'" slot="content">
        <h3 :style="{ width: '100%' }" class="text-center mb-5">
          The recipient list can be updated!
        </h3>
        <p :style="{ width: '100%' }" class="text-center">
          Select "Send All (Updatable)!" to automatically update the recipient list according to the
          recipient filter.
        </p>
        <p :style="{ width: '100%' }" class="d-flex justify-center align-center">
          Select "Send All Selected" to fix the recipient list as the users you have just chosen.
          <v-tooltip bottom color="grey lighten-5" content-class="custom-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" icon fab dark small color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-information-variant-circle-outline</v-icon>
              </v-btn>
            </template>
            <span class="custom-tooltip">
              <div>
                <p>Bạn vừa chọn toàn bộ người dùng từ danh sách</p>
                <br />
                <p>
                  - Chọn 'Send All Selected' để gửi thông báo này đến {{ userIDs.length }} người
                  dùng vừa chọn và danh sách này sẽ không đổi dù số học sinh, phụ huynh của lớp thay
                  đổi
                </p>
                <p>
                  - Chọn 'Send All (Updatable)' để gửi thông báo đến
                  {{ this.filters.classCode.length }} lớp vừa chọn và danh sách người nhận sẽ được
                  cập nhật theo danh sách học sinh , phụ huynh của lớp ở thời điểm gửi
                </p>
              </div>
            </span>
          </v-tooltip>
        </p>
      </v-card-title>
    </popup-confirm>
    <v-dialog v-if="templateDiaglog" v-model="templateDiaglog" width="1400" persistent>
      <v-card :style="{ borderRadius: `25px`, overflowY: 'hidden' }"
        ><TemplateUsing
          :templateDiaglog="templateDiaglog"
          @closeDialog="handelCloseUsingTemplate"
          @usedTemplate="appendTemplate"
      /></v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * * This folder has a typo error so i don't want to delete it
 * * just in case when u need to import this one
 * ! import { center_managerment } from '@/api/center-management.js'
 * * this one just for Oauth2
 * todo : import { getObjectPermission } from '../../common/index'
 */
import { announcement } from '@/api/announcement'
import { account_managerment } from '@/api/account-management'
import { class_managerment } from '@/api/class-management'
import { VueEditor } from 'vue2-editor'
import dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import TemplateUsing from './TemplateUsing.vue'
import AnnounTable from './AnnounTable.vue'

import Autocomplete from '@/components/Utils/AutocompleteAnnoncement.vue'
import PopupConfirm from './PopupCreateAnnouncement.vue'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export default {
  components: {
    'vue-editor': VueEditor,
    'date-picker': DatePicker,
    'popup-confirm': PopupConfirm,

    AnnounTable,
    TemplateUsing,
    Autocomplete,
  },
  data() {
    return {
      dayjs: dayjs,

      form: false,
      templateDiaglog: false,
      selectedAnnouncementTarget: [],
      repeatID: 'ONCE',
      updatedTarget: '',
      selectedFilter: '',
      announcementID: this.$route.params.announID ? this.$route.params.announID : 0,
      centers: [],
      locationIDs: null,
      isStudent: false,
      checkSuccess: false,
      checkError: false,
      repeatList: [
        {
          repeatID: 1,
          repeatText: 'ONCE',
        },
        {
          repeatID: 2,
          repeatText: 'DAILY',
        },
        {
          repeatID: 3,
          repeatText: 'WEEKLY',
        },
        {
          repeatID: 4,
          repeatText: 'MONTHLY',
        },
      ],
      workGroup: [],
      studentList: [],
      userList: [],
      classList: [],
      statusList: [],
      allReceiverType: JSON.parse(process.env.VUE_APP_ANNOUNCEMENT_RECEIVER_TYPE),
      userIDs: [],
      classID: 0,
      classStatus: 3,
      reciverType: '',
      classStatusIDs: 3,
      untilDate: dayjs(new Date()).format('DD/MM/YYYY'),
      startDate: dayjs(new Date()).format('DD/MM/YYYY'),
      startTime: dayjs().add(30, 'minutes').toDate(),
      isLoading: false,
      isLoading1: false,
      editor: '',

      //announcement template & edit announcement categories
      formCategory: false,
      catName: '',
      catList: [],
      errorEdittor: false,
      catListUpdate: [],
      dialogH: false,
      templateTitle: '',
      templateContext: '',
      appendedTemplate: {},
      formTemplateTags: false,
      errCatName: '',
      templateTags: [],

      //autocomplete for calling API
      headers: [],
      filters: {
        location: [],
        workgroup: [],
        classStattus: [],
        classCode: [],
      },
    }
  },

  computed: {},

  watch: {
    editor: function () {
      if (!this.editor || this.editor.trim() === '') {
        this.errorEdittor = true
      } else {
        this.errorEdittor = false
      }
    },
  },
  created() {
    this.init()
    /**
     * * Someday need to import this or just never user xD
     */
    // this.getStudentList()
    // this.getStudentClassList()
  },
  mounted() {},
  methods: {
    /**
     * * sum up of every promise that have to be done
     */
    async init() {
      await this.getCategoryList()
      if (!this.announcementID) {
        await this.getCenter()
        await this.getWorkGroup()
        await this.getClassStatus()
      } else await this.appendAnnouncement()
    },

    /**
     * * set show autocomplete
     */
    showAutocomplete(index) {
      switch (index) {
        case 0:
        case 1:
          return true
        case 2:
        case 3:
          return this.isStudent ? true : false
      }
    },

    /**
     * * check update list target, selected filter
     */
    checkUpdatedTarget() {
      let checkTarget
      if (this.userIDs.length > 0 && !this.announcementID) {
        const seenUsernames = new Set()
        const uniqueArr = this.userIDs.filter(obj => {
          if ('username' in obj) {
            return !seenUsernames.has(obj.username) && seenUsernames.add(obj.username)
          }
          return true // Keep objects without userame (e.g., "student" objects)
        })
        checkTarget = uniqueArr.length === this.userIDs.length ? true : false
      } else if (this.filters.classCode.length > 0) {
        checkTarget = this.filters.classCode.length === this.classList.length ? true : false
      } else if (this.filters.classStattus.length > 0) {
        checkTarget = this.filters.classStattus.length === this.statusList.length ? true : false
      } else if (this.filters.workgroup.length > 0) {
        checkTarget = this.filters.workgroup.length === this.workGroup.length ? true : false
      } else if (this.filters.location.length > 0) {
        checkTarget = this.filters.location.length === this.centers.length ? true : false
      } else checkTarget = 'error'
      return checkTarget
    },
    getUpdateTarget() {
      const receiver = {}
      receiver.receiverType =
        this.filters.classCode.length > 0
          ? this.allReceiverType[1]
          : this.filters.workgroup.length > 0
          ? this.allReceiverType[2]
          : this.allReceiverType[3]
      receiver.receiverNum =
        this.filters.classCode.length > 0
          ? this.filters.classCode.length
          : this.filters.workgroup.length > 0
          ? this.filters.workgroup.length
          : this.filters.location.length
      return receiver
    },
    checkSelectedFilter() {
      if (this.userIDs.length > 0) {
        const seenUsernames = new Set()
        const uniqueArr = this.userIDs.filter(obj => {
          if ('username' in obj) {
            return !seenUsernames.has(obj.username) && seenUsernames.add(obj.username)
          }
          return true // Keep objects without userame (e.g., "student" objects)
        })
        return uniqueArr.length === this.userIDs.length ? true : false
      }
    },

    /**
     * ? onClick on Search button when select all the classStatus, workgroup, center, classcode
     */
    searchStudent() {
      const locations = this.getIdArray(
        this.centers,
        this.filters.location,
        'location',
        'locationID'
      )
      const classCodes = this.getIdArray(
        this.classList,
        this.filters.classCode,
        'classCode',
        'classID'
      )
      const workgroups = this.getIdArray(
        this.workGroup,
        this.filters.workgroup,
        'groupName',
        'groupID'
      )
      if (this.filters.location.length > 0 || this.filters.workgroup.length > 0)
        this.getStudentClassList(locations, classCodes, workgroups)
    },
    /**
     * * Create autocomplete to use 4 search field and all the things related to
     * !logHeader to get the list
     * !setFilter to get classList
     */
    logHeader() {
      this.headers = [
        {
          announLabel: 'Center',
          value: 'location',
          items: this.centers.map(el => el.location),
        },
        {
          announLabel: 'Workgroup',
          value: 'workgroup',
          items: this.workGroup.map(el => el.groupName),
        },
        {
          announLabel: 'Class status',
          value: 'classStattus',
          items: this.statusList.map(el => el.classStatus),
        },
        {
          announLabel: 'Class',
          value: 'classCode',
          items: this.classList.map(el => el.classCode),
        },
      ]
    },

    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      const locationIDs = this.getIdArray(
        this.centers,
        this.filters.location,
        'location',
        'locationID'
      )
      const classStatuses = this.getIdArray(
        this.statusList,
        this.filters.classStattus,
        'classStatus',
        'classStatusID'
      )
      this.isStudent =
        this.filters.workgroup.includes('Students') || this.filters.workgroup.includes('Guardiant')
      this.checkUpdatedTarget()
      if (this.filters.location.length > 0 && this.filters.classStattus.length > 0)
        this.getClass(locationIDs, classStatuses)
    },

    /**
     * * handle close Using Template feature
     */
    handelCloseUsingTemplate(newValue) {
      this.templateDiaglog = newValue
    },

    handleUserSelected(data) {
      this.userIDs = data
    },
    /**
     * * set template for annoucement onclick Using Template
     */
    appendTemplate(newValue) {
      this.appendedTemplate = newValue
      const templateArr = newValue.tagIDs
      this.templateTitle = newValue.templateTitle
      this.editor = newValue.description
      this.templateTags = this.catListUpdate
        .filter(item => templateArr.includes(item.anouncementTagName))
        .map(item => item.anouncementTagID)
      this.templateContext = newValue.templateContext
    },

    /**
     * * change array of name to array of ID for API call
     */
    getIdArray(arr1, arr2, name, id) {
      return arr1?.filter(item => arr2.includes(item[name])).map(item => item[id])
    },
    returnDate(date, time) {
      function checkNumber(i) {
        if (i < 10) {
          return '0' + i
        }
        return i
      }
      const arrDate = date.split('/')
      const newDate = `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`
      if (!isNaN(new Date(time))) {
        return (
          newDate +
          'T' +
          (checkNumber(new Date(time).getHours()) +
            ':' +
            checkNumber(new Date(time).getMinutes()) +
            ':00')
        )
      } else {
        return newDate + 'T' + time
      }
    },
    /**
     * * check input
     */
    validateRequire(v) {
      return !!v || 'Required'
    },
    /**
     * * move selected class to top
     */

    moveSelectedClassToTop(arr, el) {
      const idArr = arr.map(el => el.classCode)
      if (idArr.indexOf(el) !== -1) {
        // If the element is found in the array
        const item = arr.slice(idArr.indexOf(el), idArr.indexOf(el) + 1)[0]
        arr.splice(idArr.indexOf(el), 1) // Remove the element
        arr.unshift(item) // Insert it at the beginning
      }
    },
    /**
     * * Solve asynchronus here
     * ? getCenter to get centerList
     * ? getClassStatus to get classStatusList
     * ? getClass to get class list
     * ? getWorkgroup to get workgroup list
     */
    async confirmSendNormal() {
      const announBody = {
        title: this.templateTitle,
        context: this.templateContext,
        description: this.editor, //nội dung thông báo. Có định dạng HTML
        tagIDs: this.templateTags,
        announRepeatType: this.repeatID.toUpperCase(), // ONCE, DAILY, WEEKLY, MONTHLY
        startTime: this.returnDate(this.startDate, this.startTime), //thời gian gửi mà người dùng chọn khi tạo thông báo, là Sending Time của thông báo chưa gửi (Chứa cả giờ và ngày)
        untilDate:
          this.repeatID === 'ONCE'
            ? null
            : dayjs(this.untilDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), // Do repeatType = ONCE nên sẽ không có untilDate
        workGroupIDs: this.getIdArray(
          this.workGroup,
          this.filters.workgroup,
          'groupName',
          'groupID'
        ), // Cấp độ từ trên xuống theo(*) ở dòng đầu
        locationIDs: this.getIdArray(this.centers, this.filters.location, 'location', 'locationID'),
        classStatusIDs: this.getIdArray(
          this.statusList,
          this.filters.classStattus,
          'classStatus',
          'classStatusID'
        ),
        classIDs: this.getIdArray(this.classList, this.filters.classCode, 'classCode', 'classID'),
        userIDs: this.userIDs.map(el => el.userID), //cấp này thấp nhất
        receiverType: this.allReceiverType[0],
        receiverTypeNum: this.userIDs.length,
      }
      if (this.announcementID) {
        announBody.announID = this.announcementID
        await announcement
          .editAnnouncement(announBody)
          .then(() => {
            // this.checkSuccess = true
            // setTimeout(() => {
            //   this.checkSuccess = false
            this.back()
            // }, 1000)
          })
          .catch(() => {
            this.checkError = true
            setTimeout(() => {
              this.checkError = false
            }, 1000)
          })
      } else {
        await announcement
          .createAnnouncement(announBody)
          .then(res => {
            if (res) {
              // this.checkSuccess = true
              // setTimeout(() => {
              //   this.checkSuccess = false
              this.back()
              // }, 1000)
            }
          })
          .catch(() => {
            this.checkError = true
            setTimeout(() => {
              this.checkError = false
            }, 1000)
          })
      }
    },
    async confirmSendUpdatable() {
      const announBody = {
        title: this.templateTitle,
        context: this.templateContext,
        description: this.editor, //nội dung thông báo. Có định dạng HTML
        tagIDs: this.templateTags,
        announRepeatType: this.repeatID.toUpperCase(), // ONCE, DAILY, WEEKLY, MONTHLY
        startTime: this.returnDate(this.startDate, this.startTime), //thời gian gửi mà người dùng chọn khi tạo thông báo, là Sending Time của thông báo chưa gửi (Chứa cả giờ và ngày)
        untilDate:
          this.repeatID === 'ONCE'
            ? null
            : dayjs(this.untilDate, 'DD/MM/YYYY').format('YYYY-MM-DD'), // Do repeatType = ONCE nên sẽ không có untilDate
        workGroupIDs: this.getIdArray(
          this.workGroup,
          this.filters.workgroup,
          'groupName',
          'groupID'
        ), // Cấp độ từ trên xuống theo(*) ở dòng đầu
        locationIDs: this.getIdArray(this.centers, this.filters.location, 'location', 'locationID'),
        classStatusIDs: this.getIdArray(
          this.statusList,
          this.filters.classStattus,
          'classStatus',
          'classStatusID'
        ),
        classIDs: this.getIdArray(this.classList, this.filters.classCode, 'classCode', 'classID'),
        userIDs: [], //cấp này thấp nhất
        receiverType: this.getUpdateTarget().receiverType,
        receiverTypeNum: this.getUpdateTarget().receiverNum,
      }
      if (this.announcementID) {
        announBody.announID = this.announcementID
        await announcement
          .editAnnouncement(announBody)
          .then(() => {
            // this.checkSuccess = true
            // setTimeout(() => {
            //   this.checkSuccess = false
            this.back()
            // }, 1000)
          })
          .catch(() => {
            this.checkError = true
            setTimeout(() => {
              this.checkError = false
            }, 1000)
          })
      } else {
        await announcement
          .createAnnouncement(announBody)
          .then(res => {
            if (res) {
              // this.checkSuccess = true
              // setTimeout(() => {
              //   this.checkSuccess = false
              this.back()
              // }, 1000)
            }
          })
          .catch(() => {
            this.checkError = true
            setTimeout(() => {
              this.checkError = false
            }, 1000)
          })
      }
    },
    async getCenter() {
      await account_managerment.getListCenter().then(async res => {
        if (res) {
          this.centers = res
          this.logHeader()
        }
      })
    },
    async getClassStatus() {
      await class_managerment.getClassStatus().then(async res => {
        if (res) {
          this.statusList = res
          this.logHeader()
        }
      })
    },
    async getClass(location, status) {
      await class_managerment.getClassesByLocations(location, status).then(res => {
        if (res) {
          this.classList = res
          this.logHeader()
        }
      })
    },
    async getWorkGroup() {
      this.workGroup = await announcement.getWorkgroup()
      this.logHeader()
    },
    /**
     * * get recipient list
     */
    async getStudentClassList(locations, classes, workgroups) {
      this.isLoading1 = true
      try {
        const studentList =
          classes.length > 0 && workgroups.length > 0
            ? await announcement.getRecipientsByClassIDs(classes, workgroups)
            : []

        if (studentList) {
          this.studentList = studentList
        }

        const userList =
          locations.length > 0 && workgroups.length > 0
            ? await announcement.getRecipientsByLocationIDs(locations, workgroups)
            : []

        userList?.forEach(el => {
          if (Object.prototype.hasOwnProperty.call(el, 'userId')) {
            el.userID = el.userId
            delete el.userId
          }
        })
        this.userList = [...userList, ...this.studentList]

        this.isLoading1 = false
      } catch (error) {
        console.error(error)
        // Handle errors here
        this.isLoading1 = false
      }
    },

    /**
     * * some how i don't know why i'm using setTimeOut here
     */
    // getStudentList() {
    //   setTimeout(() => {
    //     this.getStudentClassList()
    //   }, 1000)
    // },

    /**
     * * every thing about announcement category
     */
    closeDialogCategory() {
      this.dialogH = false
    },
    openEditReward() {
      this.catListUpdate = this.catList
      this.dialogH = true
    },
    async saveCategory() {
      this.$refs.formCategory.validate()
      if (this.formCategory) {
        await announcement
          .editTags([
            {
              tagID: null,
              tagName: this.catName,
            },
          ])
          .then(() => {
            this.getCategoryList()
          })
          .catch(() => {
            this.errCatName = 'This template tag name is exists'
          })
      }
    },
    async saveEditCategoryList() {
      this.$refs.formTemplateTags.validate()
      if (this.$refs.formTemplateTags.validate()) {
        const updateList = this.catListUpdate.map(el => {
          return {
            tagID: el.anouncementTagID,
            tagName: el.anouncementTagName,
          }
        })
        await announcement.editTags(updateList)
        this.getCategoryList()
        this.dialogH = false
      }
    },
    async removeTag(id) {
      await announcement
        .deleteTag(id)
        .then(() => {
          this.getCategoryList()
        })
        .catch(() => {
          this.errCatName = "This template tag ID isn't exists"
        })
    },
    async getCategoryList() {
      await announcement
        .getAllTags()
        .then(res => {
          this.catList = res.sort((a, b) => b.anouncementTagID - a.anouncementTagID)
          this.catListUpdate = res.sort((a, b) => b.anouncementTagID - a.anouncementTagID)
        })
        .catch(() => {
          this.catList = []
        })
    },
    createNew() {
      this.$refs.confirmCreateAnnoun.open()
    },
    async appendAnnouncement() {
      await announcement.getAnnounByID(this.announcementID).then(async res => {
        // console.log(this.headers)
        this.templateTitle = res.title
        this.templateContext = res.context
        this.editor = res.description
        this.templateTags = res.tagIDs
        this.repeatID = res.announRepeatType
        this.startDate = dayjs(res.startTime.split('T')[0]).format('DD/MM/YYYY')
        this.startTime = dayjs(res.startTime.split('T')[1], 'HH:mm:ss').toDate()
        this.untilDate = dayjs(res.untilDate).format('DD/MM/YYYY')
        /**
         * *Start to deal with filters and recepients table
         */
        //workgroup filter
        this.workGroup = await announcement.getWorkgroup()
        this.filters.workgroup =
          res.workGroupIDs.length > 0
            ? this.getIdArray(this.workGroup, res.workGroupIDs, 'groupID', 'groupName')
            : []

        //center filter
        this.centers = await account_managerment.getListCenter()
        if (res.locationIDs) {
          this.filters.location = this.getIdArray(
            this.centers,
            res.locationIDs,
            'locationID',
            'location'
          )
        }

        //classStatus filter
        this.statusList = await class_managerment.getClassStatus()
        if (res.classStatusIDs.length > 0) {
          this.filters.classStattus = this.getIdArray(
            this.statusList,
            res.classStatusIDs,
            'classStatusID',
            'classStatus'
          )
        }

        //class filter
        if (res.locationIDs.length > 0 && res.classStatusIDs.length > 0) {
          this.classList = await class_managerment.getClassesByLocations(
            res.locationIDs,
            res.classStatusIDs
          )
        }

        if (res.classIDs.length > 0) {
          this.filters.classCode = this.getIdArray(
            this.classList,
            res.classIDs,
            'classID',
            'classCode'
          )
        }

        if (this.filters.classCode.length > 0)
          this.filters.classCode.forEach(el => this.moveSelectedClassToTop(this.classList, el))

        //Log header
        this.logHeader()

        //center filter
        this.userIDs = res.userIDs
        this.isStudent =
          this.filters.workgroup.includes('Students') ||
          this.filters.workgroup.includes('Guardiant')
        this.getStudentClassList(res.locationIDs, res.classIDs, res.workGroupIDs)
      })
    },

    /**
     * * back to previous page
     */
    back() {
      window.history.back()
    },
  },
}
</script>
<style lang="scss" scoped>
.v-tooltip__content.custom-tooltip {
  opacity: 1 !important; // This did not set the opacity to 100% opaque
  box-shadow: -1px -1px 7px 0px #2196f3;
  color: #000000;
  max-width: 550px;
  text-align: justify;
  padding: 20px;
}

.add-img {
  height: 90%;
  img {
    height: 100%;
  }
}
.link {
  color: #1976d2;
  &:hover {
    cursor: pointer;
  }
}
.edit-reference-category {
  width: 200px;
  height: auto;
}
.editor {
  position: relative;
  span {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(10px, -50%);
    background-color: #ffff;
    color: '#5E6368';
  }
}
.pick-time {
  margin-right: 6px;
}
.pick-time.time {
  width: 100px;
}
.pick-time.date {
  margin-left: 6px;
  width: 200px;
}
.search-btn {
  &:hover {
    cursor: pointer;
  }
}
.rounded {
  border-radius: 50px;
}
.alert {
  width: 300px;
  position: absolute;
  right: 0;
  top: 5%;
  z-index: 999;
}
</style>
