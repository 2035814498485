<template>
  <div class="pa-10 pt-5">
    <v-row dense align="center" justify="center" style="margin-bottom: -30px">
      <v-col cols="6">
        <div class="d-flex justify-space-between">
          <h2>List of Template</h2>
          <p>Total: {{ filtered.length }} templates</p>
        </div>
      </v-col>
      <v-col cols="6" class="pl-10">
        <div class="d-flex justify-space-between pb-3">
          <h2>Preview</h2>
          <img
            @click="$emit('closeDialog', !templateDiaglog)"
            :style="{ width: '30px', height: '30px', cursor: 'pointer ' }"
            src="../../assets/images/icon-close2.svg"
            alt=""
          />
        </div>
      </v-col>
    </v-row>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <v-row :style="{ maxHeight: '500px' }">
      <v-col cols="6">
        <v-data-table
          v-if="!isLoading"
          :headers="headers"
          :items-per-page="itemsPerPage1"
          :items="filtered"
          hide-default-footer
          :class="'elevation-0 mb-5 table-monthstu'"
          fixed-header
          height="45vh"
        >
          <template v-slot:header="{ props }">
            <tr class="fixed-row-filter">
              <td v-for="header in props.headers" :key="header.value">
                <div :class="'d-flex justify-center'" v-if="filters[header.value]">
                  <Autocomplete
                    v-if="filters.hasOwnProperty(header.value)"
                    @setFilter="setFilter"
                    :styleBorder="'border-bottom'"
                    :keyFilter="header.value"
                    :selectedInit="filters[header.value]"
                    :listItem="groupColumnValueList(header.value)"
                  />
                </div>
                <div v-else>
                  <td></td>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.templateTitle`]="{ item }">
            <p
              @click="
                selectedTemplate = item
                dialogSelectTemplate = true
              "
              class="usingTemplateTitle"
            >
              {{ item.templateTitle }}
            </p>
          </template>
          <template v-slot:[`item.tagIDs`]="{ item }">
            <p :style="{ width: '140px' }">{{ item.tagIDs }}</p>
          </template>
          <template v-slot:[`item.updateBy`]="{ item }">
            <p class="text-center">{{ item.updateBy }}</p>
          </template>
          <template v-slot:[`item.createBy`]="{ item }">
            <p class="link" @click="viewAccount(item.createUserID)">
              {{ item.createBy }}
            </p>
          </template>
        </v-data-table>
        <div :style="{ width: '50%', bottom: '20px', position: 'absolute' }">
          <div class="d-flex align-center justify-space-between">
            <v-select
              outlined
              hide-details
              dense
              :value="itemsPerPage1"
              :items="[20, 50, 100, 200]"
              @change="itemsPerPage1 = parseInt($event, 10)"
              style="height: 32px; max-width: 25%"
            ></v-select>
            <v-pagination
              v-model="page1"
              class="mr-8"
              :length="Math.ceil(filtered.length.toFixed() / itemsPerPage1)"
              :total-visible="7"
              elevation="0"
            ></v-pagination>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="pl-10"
        ><div v-if="dialogSelectTemplate">
          <v-card height="400" style="overflow: scroll">
            <v-card-title
              :class="'mb-3'"
              :style="{
                width: '100%',
              }"
            >
              <h2>{{ selectedTemplate.templateTitle }}</h2>
            </v-card-title>
            <v-card-text>
              <div
                :style="{
                  paddingTop: '3px',
                }"
              >
                <p class="mb-4" v-html="selectedTemplate.templateContext"></p>
                <p v-html="selectedTemplate.description"></p>
              </div>
            </v-card-text>
          </v-card>
          <div :style="{ width: '12%', bottom: '23px', right: '0', position: 'absolute' }">
            <v-btn color="primary" @click="usingTemplate()"> Use this template </v-btn>
          </div>
        </div></v-col
      >
    </v-row>
  </div>
</template>

<script>
import { announcement } from '@/api/announcement'

import dayjs from 'dayjs'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
export default {
  components: { Autocomplete },
  setup() {
    return {}
  },
  props: {
    templateDiaglog: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      dayjs: dayjs,
      selectedTemplate: null,
      dialogSelectTemplate: false,
      hoverID: '',
      page1: 1,
      itemsPerPage1: 20,
      listTemplate: [],
      headers: [
        {
          text: 'Template Title',
          value: 'templateTitle',
          sortable: false,
        },
        {
          text: 'Tags',
          value: 'tagIDs',
          sortable: false,
        },
        {
          text: 'Last update by',
          value: 'updateBy',
          sortable: false,
        },
        {
          text: 'Update at',
          value: 'updateAt',
          sortable: false,
        },
      ],
      filters: {
        templateTitle: [],
        tagIDs: [],
        updateBy: [],
        updateAt: [],
      },
      listTemplateTags: [],
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  watch: {},
  computed: {
    filtered() {
      return this.listTemplate?.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            (f === 'tagIDs'
              ? this.filters[f].reduce((value, el) => {
                  if (d.tagIDs.includes(el)) {
                    value = true
                  }
                  return value
                }, false)
              : this.filters[f].includes(d[f]))
          )
        })
      })
    },
  },
  methods: {
    getJsonArray(data) {
      return JSON.parse(data.replace(/'/g, '"'))
    },
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('usingTemplateFilter', JSON.stringify(this.filters))
    },
    viewTemplate(template) {
      ;(this.dialogTemplate = true), (this.selectedTemplate = template)
    },
    groupColumnValueList(val) {
      if (val === 'tagIDs') {
        return [
          ...new Set(
            this.listTemplate
              .reduce((a, c) => [...a, c[val]], [])
              .map(c => (c.includes(',') ? c.split(',') : c))
              .flat()
              .map(c => c.trim())
          ),
        ]
      }
      return this.listTemplate.map(d => d[val]).filter(y => y)
    },
    usingTemplate() {
      this.$emit('usedTemplate', this.selectedTemplate)
      this.$emit('closeDialog', !this.templateDiaglog)
    },
    async getCategoryList() {
      this.isLoading = true
      await announcement
        .getAllTags()
        .then(res => {
          this.listTemplateTags = res
          this.isLoading = false
        })
        .catch(() => {
          this.listTemplateTags = []
          this.isLoading = false
        })
    },
    async getAllTemplate() {
      this.isLoading = true
      await announcement
        .getAllTemplate()
        .then(res => {
          this.listTemplate = res.map(el => {
            return {
              ...el,
              updateAt: el.updateAt ? dayjs(el.updateAt).format('DD/MM/YYYY') : '',
              tagIDs:
                el.tagIDs.length > 0
                  ? this.listTemplateTags
                      .filter(e => el.tagIDs.includes(e.anouncementTagID))
                      .map(e => e.anouncementTagName)
                      .join(', ')
                  : '',
            }
          })
          this.isLoading = false
        })
        .catch(() => {
          this.listTemplate = []
          this.isLoading = false
        })
    },
    async init() {
      await this.getCategoryList()
      await this.getAllTemplate()
    },
  },
}
</script>

<style lang="scss" scoped>
.usingTemplateTitle {
  color: #0288d1;
  &:hover {
    cursor: pointer;
  }
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181);
  border-radius: 10px;
  border: 3px solid #ffffff;
}
</style>
