<template>
  <div id="course-management">
    <v-row no-gutters>
      <v-col md="12">
        <div class="pa-10 pt-5">
          <p>Total selected: {{ selected.length }} accounts</p>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filtered"
            :items-per-page="10000000000"
            item-key="userID"
            show-select
            hide-default-footer
            class="elevation-0 pt-5"
            fixed-header
            height="30vh"
          >
            <template v-slot:header="{ props }">
              <tr class="fixed-row-filter">
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :style="'width:' + header.width + '; min-width:' + header.width + ';'"
                >
                  <div v-if="filters.hasOwnProperty(header.value)">
                    <Autocomplete
                      v-if="filters.hasOwnProperty(header.value)"
                      @setFilter="setFilter"
                      :styleBorder="'border-bottom'"
                      :keyFilter="header.value"
                      :selectedInit="filters[header.value]"
                      :listItem="groupColumnValueList(header.value)"
                    />
                  </div>
                </th>
              </tr>
            </template>
            <template v-slot:[`item.no`]="{ index }">
              <p>{{ index + 1 }}</p>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Autocomplete from '@/components/Utils/Autocomplete.vue'
import message from '@/components/Utils/message'
export default {
  components: { Autocomplete },
  props: {
    students: {
      type: Array,
    },
    selectedAnnouncementTarget: {
      type: Array,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '50px',
          sortable: false,
        },
        {
          text: 'Name',
          value: 'fullName',
          width: '30%',
          sortable: false,
        },
        {
          text: 'Center',
          value: 'location',
          width: '30%',
          sortable: false,
        },
        {
          text: 'Type',
          value: 'workGroup',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Class',
          value: 'classCode',
          width: '20%',
          sortable: false,
        },
      ],
      classList: [],
      // classGroup: [],
      // classStatus: [],
      filters: {
        fullName: [],
        location: [],
        workGroup: [],
        classCode: [],
      },
      currentCenter: JSON.parse(localStorage.getItem('currentCenter')),
      centers: [],
      center: '',
      errorDialog: false,
      message: message,
    }
  },
  computed: {
    filtered() {
      return this.students.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f])
        })
      })
    },
  },
  created() {
    if (localStorage.getItem('AccountAnnounFilter')) {
      this.filters = JSON.parse(localStorage.getItem('AccountAnnounFilter'))
    }
    if (this.selectedAnnouncementTarget.length > 0) {
      this.selected = this.students.reduce((acc, el) => {
        if (this.selectedAnnouncementTarget.includes(el.userID)) {
          acc.push(el)
        }
        return acc
      }, [])
    }
    this.init()
  },
  watch: {
    selected: function (value) {
      this.$emit('selectedUser', value)
    },
  },
  methods: {
    setFilter(objectFilterChange) {
      this.filters = {
        ...this.filters,
        [objectFilterChange.name]: objectFilterChange.filter,
      }
      localStorage.setItem('AccountAnnounFilter', JSON.stringify(this.filters))
    },
    init() {
      this.students.map(el => {
        return {
          ...el,
          registrationDate: el.registrationDate
            ? dayjs(el.registrationDate).format('DD/MM/YYYY')
            : '',
        }
      })
    },
    groupColumnValueList(val) {
      return this.students.map(d => d[val]).filter(y => y)
    },

    back() {
      window.history.back()
    },
    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.link {
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #427df2;
  }
}
</style>
