var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10 pt-5"},[_c('v-row',{staticStyle:{"margin-bottom":"-30px"},attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',[_vm._v("List of Template")]),_c('p',[_vm._v("Total: "+_vm._s(_vm.filtered.length)+" templates")])])]),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-space-between pb-3"},[_c('h2',[_vm._v("Preview")]),_c('img',{style:({ width: '30px', height: '30px', cursor: 'pointer ' }),attrs:{"src":require("../../assets/images/icon-close2.svg"),"alt":""},on:{"click":function($event){return _vm.$emit('closeDialog', !_vm.templateDiaglog)}}})])])],1),(_vm.isLoading)?[_c('div',{staticClass:"pa-6"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_vm._e(),_c('v-row',{style:({ maxHeight: '500px' })},[_c('v-col',{attrs:{"cols":"6"}},[(!_vm.isLoading)?_c('v-data-table',{class:'elevation-0 mb-5 table-monthstu',attrs:{"headers":_vm.headers,"items-per-page":_vm.itemsPerPage1,"items":_vm.filtered,"hide-default-footer":"","fixed-header":"","height":"45vh"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('td',{key:header.value},[(_vm.filters[header.value])?_c('div',{class:'d-flex justify-center'},[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_c('div',[_c('td')])])}),0)]}},{key:"item.templateTitle",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"usingTemplateTitle",on:{"click":function($event){_vm.selectedTemplate = item
              _vm.dialogSelectTemplate = true}}},[_vm._v(" "+_vm._s(item.templateTitle)+" ")])]}},{key:"item.tagIDs",fn:function(ref){
              var item = ref.item;
return [_c('p',{style:({ width: '140px' })},[_vm._v(_vm._s(item.tagIDs))])]}},{key:"item.updateBy",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(item.updateBy))])]}},{key:"item.createBy",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"link",on:{"click":function($event){return _vm.viewAccount(item.createUserID)}}},[_vm._v(" "+_vm._s(item.createBy)+" ")])]}}],null,true)}):_vm._e(),_c('div',{style:({ width: '50%', bottom: '20px', position: 'absolute' })},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-select',{staticStyle:{"height":"32px","max-width":"25%"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage1,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage1 = parseInt($event, 10)}}}),_c('v-pagination',{staticClass:"mr-8",attrs:{"length":Math.ceil(_vm.filtered.length.toFixed() / _vm.itemsPerPage1),"total-visible":7,"elevation":"0"},model:{value:(_vm.page1),callback:function ($$v) {_vm.page1=$$v},expression:"page1"}})],1)])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"6"}},[(_vm.dialogSelectTemplate)?_c('div',[_c('v-card',{staticStyle:{"overflow":"scroll"},attrs:{"height":"400"}},[_c('v-card-title',{class:'mb-3',style:({
              width: '100%',
            })},[_c('h2',[_vm._v(_vm._s(_vm.selectedTemplate.templateTitle))])]),_c('v-card-text',[_c('div',{style:({
                paddingTop: '3px',
              })},[_c('p',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(_vm.selectedTemplate.templateContext)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.selectedTemplate.description)}})])])],1),_c('div',{style:({ width: '12%', bottom: '23px', right: '0', position: 'absolute' })},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.usingTemplate()}}},[_vm._v(" Use this template ")])],1)],1):_vm._e()])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }