<template>
  <v-dialog v-model="confirmDialog" :max-width="maxWidth || 515" :width="width">
    <v-card class="pa-5">
      <div :style="{ width: 'fit-content', marginLeft: 'auto' }">
        <img @click="cancel" src="../../assets/images/icon-close-round.svg" alt="" srcset="" />
      </div>
      <div class="d-flex flex-column">
        <slot name="icon" />

        <slot name="title" />
        <slot name="content" />
      </div>
      <v-card-actions style="gap:10px">
        <v-btn :style="{ width: '32%' }" outlined :color="cancelColor || 'primary'" @click="cancel">
          <template v-if="cancelText">
            <p v-html="cancelText"></p>
          </template>
          <template v-else> Cancel </template>
        </v-btn>
        <v-btn
          :style="{ width: '32%' }"
          :color="confirmColor || 'primary'"
          :disabled="checkConfirm"
          class="white--text"
          @click="confirmNormal"
        >
          <template v-if="confirmSend">
            {{ confirmSend }}
          </template>
          <template v-else> Confirm </template>
        </v-btn>
        <v-btn
          :style="{ width: '32%' }"
          :color="confirmColor || 'primary'"
          :disabled="checkConfirm"
          class="white--text"
          @click="confirmUpdate"
        >
          <template v-if="confirmSendAll">
            {{ confirmSendAll }}
          </template>
          <template v-else> Confirm All </template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    maxWidth: Number,
    confirmColor: String,
    cancelColor: String,
    cancelText: String,
    confirmSend: String,
    confirmSendAll: String,
    width: Number,
    checkConfirm: Boolean,
  },
  data() {
    return {
      confirmDialog: false,
      item: null,
    }
  },
  methods: {
    open(item) {
      this.confirmDialog = true
      this.item = item
    },
    cancel() {
      this.$emit('cancel', this.item)
      this.confirmDialog = false
    },
    confirmNormal() {
      this.$emit('confirmNormal', this.item)
      this.confirmDialog = false
    },
    confirmUpdate() {
      this.$emit('confirmUpdate', this.item)
      this.confirmDialog = false
    },
  },
}
</script>
<style lang="scss"></style>
